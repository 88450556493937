// i18next-extract-mark-ns-start products-sabr-bearing

import { SABR_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductSabrPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							SABR can be used to model deep-groove and
							angular-contact ball bearings, tapered, cylindrical,
							needle roller, and thrust roller bearings that are
							either casing-mounted or encapsulated between two
							rotating components. Essential for calculating the
							bearing life, misalignment and loading algorithms are
							based on bearing calculations that the bearing design
							and manufacturing companies use, including ISO 16281 and
							ISO 281 standards.
						</p>
						<p>
							Our longstanding relationships with bearing companies,
							including SKF, Timken and Koyo, have been essential to
							extending the range of bearings in our packaged
							software. &nbsp;With these bearings integrated, SABR can
							efficiently calculate and visually represent the exact
							loading regime of the bearing, indicating the overall
							use of the bearing race as well as potential areas of
							excessive load.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Ability to analyse either standard or custom
								bearings
							</li>
							<li>
								Bearing-life calculation based on misalignment,
								roller crowning and level of loading, preload and
								clearance
							</li>
							<li>
								Quick bearing-positioning tool, allowing fast
								iterative assessments of transmission layout
							</li>
							<li>Gear-mounted needle roller bearing analysis</li>
							<li>Integrated extensive bearing catalogue</li>
							<li>
								Ability to define and develop bespoke bearing
								catalogue
							</li>
							<li>
								Ability to define and develop bearing mounting fit
								requirements
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{SABR_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductSabrPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-sabr-bearing", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/sabr-hero.jpg" }) {
		...imageBreaker
	}
}
`;
